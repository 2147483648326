export const Sports = [
    {
        id:1,
        src:"./sageEvents/sports/cricket.jpg",
    },
    {
        id:2,
        src:"./sageEvents/sports/badminton.jpg",
    },
    {
        id:3,
        src:"./sageEvents/sports/basketball.jpg",
    },
    {
        id:4,
        src:"./sageEvents/sports/billiards.jpg",
    },
    {
        id:5,
        src:"./sageEvents/sports/chess.jpg",
    },
    {
        id:6,
        src:"./sageEvents/sports/deadlift.jpg",
    },
    {
        id:7,
        src:"./sageEvents/sports/futsal.jpg",
    },
    {
        id:8,
        src:"./sageEvents/sports/kabaddi.jpg",
    },
    {
        id:9,
        src:"./sageEvents/sports/table_tennis.jpg",
    },
    {
        id:10,
        src:"./sageEvents/sports/netball.jpg",
    },
    {
        id:11,
        src:"./sageEvents/sports/volleyball.jpg",
    }
]
export const Tech = [
    {
        id:1,
        src:"./sageEvents/tech/codecode.jpg"
    },
    {
        id:2,
        src:"./sageEvents/tech/labgame.jpg"
    },
    {
        id:3,
        src:"./sageEvents/tech/mobilegaming.jpg"
    },
    {
        id:4,
        src:"./sageEvents/tech/roborace.jpg"
    },
    {
        id:5,
        src:"./sageEvents/tech/webify.jpg"
    },
]
export const Cultural = [
    {
        id:1,
        src:"./sageEvents/cultural/fashion.jpg"
    },
    {
        id:2,
        src:"./sageEvents/cultural/groupdance.jpg"
    },
    {
        id:3,
        src:"./sageEvents/cultural/bob.jpg"
    },
    {
        id:4,
        src:"./sageEvents/cultural/modelhunt.jpg"
    },
    {
        id:5,
        src:"./sageEvents/cultural/rap.jpg"
    },
    {
        id:6,
        src:"./sageEvents/cultural/solodance.jpg"
    },
    {
        id:7,
        src:"./sageEvents/cultural/voicestra.jpg"
    },
]
export const Literary = [
    {
        id:1,
        src:"./sageEvents/literary/debate.jpg"
    },
    {
        id:2,
        src:"./sageEvents/literary/hunt.jpg"
    },
    {
        id:3,
        src:"./sageEvents/literary/poetry.jpg"
    },
    {
        id:4,
        src:"./sageEvents/literary/reelmaking.jpg"
    },
    {
        id:5,
        src:"./sageEvents/literary/shortfilm.jpg"
    }
]
export const Management = [
    {
        id:1,
        src:"./sageEvents/management/abhivyakti.jpg"
    },
    {
        id:2,
        src:"./sageEvents/management/bidTowin.jpg"
    },
    {
        id:3,
        src:"./sageEvents/management/brandmakeover.jpg"
    },
    {
        id:4,
        src:"./sageEvents/management/mtw.jpg"
    },
    {
        id:5,
        src:"./sageEvents/management/patra.jpg"
    }
]
export const IMAGES = [
    {
        id: 0,
        imageSrc:"./slider/1.jpeg"
    },
    {
        id: 1,
        imageSrc:"./slider/2.png"
    },
    {
        id: 2,
        imageSrc:"./slider/3.png"
    }
]
